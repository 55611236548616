
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
} from 'vue'
import { useViewPort } from './composables/useViewPort'
import { useStore } from './store'
import AppLoader from '@/components/app-loader.vue'
import { EventBuss } from './types/global'
import { AppMutation } from './store/types'
import screenfull from 'screenfull'
import {
    getDeviceOrientation,
    isIEBrowser,
    isMobile as isAMobile
} from './utils'
import {
    msgEditCustomChip,
    msgSetting,
    msgEditPassword,
    msgBetHistory,
    msgLanguage
} from '@/components/dialogs'

import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from './types/MessageBox'
import { useRoute, useRouter } from 'vue-router'
import { ROUTES } from './router'

import audioPlayer from './utils/sounds'
import { useAssetPreload } from '@/composables/useAssetPreload'

export default defineComponent({
    components: {
        'app-loader': AppLoader,
        'msg-edit-custom-chip': msgEditCustomChip,
        'msg-setting': msgSetting,
        'msg-edit-password': msgEditPassword,
        'msg-bet-history': msgBetHistory,
        'msg-language': msgLanguage
    },
    setup() {
        const appRoot = ref<HTMLElement | undefined>(undefined)
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const route = useRoute()
        const router = useRouter()
        const showMainView = ref(true)
        const showEditChipMsg = ref(false)
        const showSetting = ref(false)

        const { refVar, PreloadAssets } = useAssetPreload()

        const toastMessage = ref<string>('This is Toast')
        const toastMessageCounter = ref<number>(0)

        const dialogCollection = ref<string[]>([])

        const showLanguageDialogBox = ref<boolean>(false)
        const showSettingDialogBox = ref<boolean>(false)
        const showAgreeDialogBox = ref<boolean>(false)
        const showChipsDialogBox = ref<boolean>(false)
        const showRuleDialogBox = ref<boolean>(false)
        const showRecordDialogBox = ref<boolean>(false)
        const showMessageDialogBox = ref<boolean>(false)
        const showMessageDialogTipBox = ref<boolean>(false)
        const showMessageDialogTipBoxConcent = ref<boolean>(false)
        const showMessageDialogVipBox = ref<boolean>(false)
        const showMessageDialogMediaSettingBox = ref<boolean>(false)
        const showGoodWaySetting = ref(false)
        const showAvatarDialog = ref(false)
        const showWitchPlayMsgBox = ref(false)
        const showCancelConfirmMessage = ref(false)
        const showuserInfoAndBetRecord = ref(false)
        const showMobileRecord = ref(false)
        const showEditPassword = ref(false)
        const showMediaSetting = ref(false)

        let isPageBlured = false

        let toastMessageInterval: boolean | number = false

        //#region Computed Helpers
        const isMobile = computed((): boolean => store.getters['isMobile'])
        const deviceOrientation = computed(
            (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
        )
        //#endregion

        const { windowResize } = useViewPort(appRoot.value)

        //#region Vue Helpers
        onBeforeMount(() => {
            window.addEventListener('resize', handleResizeWindow)

            // listen to page in active and active state
            document.addEventListener(
                'visibilitychange',
                handlePageVisibilityChange
            )

            window.addEventListener('blur', handlePageBlur)
            window.addEventListener('focus', handlePageFocus)

            emitter.on(EventBuss.TOAST_MESSAGE, handleToastMessage)
            emitter.on(EventBuss.DIALOG, handleDialogEvent)
            emitter.on(EventBuss.REQUEST_FULL_SCREEN, requestFullScreen)

            if (isMobile.value) {
                window.addEventListener(
                    'orientationchange',
                    handleOrientationChange
                )
            }
        })

        onMounted(() => {
            handleResizeWindow()

            if (!audioPlayer.isLoaded) {
                audioPlayer.isOn = canPlaySound.value ? 1 : 0
                audioPlayer.LoadSounds()
            }

            PreloadAssets()
        })

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResizeWindow)
            document.removeEventListener(
                'visibilitychange',
                handlePageVisibilityChange
            )

            window.removeEventListener('blur', handlePageBlur)
            window.removeEventListener('focus', handlePageFocus)

            emitter.off(EventBuss.TOAST_MESSAGE, handleToastMessage)
            emitter.off(EventBuss.DIALOG, handleDialogEvent)
            emitter.off(EventBuss.REQUEST_FULL_SCREEN, requestFullScreen)

            if (isMobile.value) {
                window.removeEventListener(
                    'orientationchange',
                    handleOrientationChange
                )
            }
        })
        //#endregion

        //#region Helpers

        const handlePageBlur = () => {
            isPageBlured = true
        }

        const handlePageFocus = () => {
            if (isPageBlured === true) {
                handlePageVisibilityChange()
            }
        }

        const handlePageVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                isPageBlured = false
                emitter.emit(EventBuss.PAGE_ON_VISIBLE)
            }
        }

        const handleResizeWindow = () => {
            emitter.emit(EventBuss.WINDOW_RESIZED)
            windowResize(appRoot.value)
        }

        const handleToastMessage = (_message: string) => {
            toastMessageCounter.value = 9
            toastMessage.value = _message
            if (typeof toastMessageInterval !== 'number') {
                toastMessageInterval = setInterval(() => {
                    toastMessageCounter.value -= 1
                    if (
                        toastMessageCounter.value <= 0 &&
                        typeof toastMessageInterval === 'number'
                    ) {
                        clearInterval(toastMessageInterval)
                        toastMessageInterval = false
                        toastMessage.value = ''
                    }
                }, 150)
            }
        }

        const initSound = (e: Event) => {
            // e.preventDefault()

            if (!isUserInteracted.value) {
                store.commit(AppMutation.SET_USER_INTERACTION)
                audioPlayer.Start()
                audioPlayer.isOn = canPlaySound.value ? 1 : 0

                audioPlayer.soundEffect = musicCanPlay.value

                audioPlayer.SetMusicVolume(musicVolume.value)
                audioPlayer.soundsVolume = soundVoulume.value

                if (route.name !== ROUTES.LOGIN && musicCanPlay.value !== 0)
                    audioPlayer.PlayMusic()
                // console.log('init sound')
            }
        }

        const handleOrientationChange = () => {
            // if (screenfull.isEnabled && !screenfull.isFullscreen) {
            //     const docEl = document.getElementById('app') as HTMLElement
            //     if (getDeviceOrientation() === 'landscape') {
            //         screenfull
            //             .request(docEl, { navigationUI: 'hide' })
            //             .then(() => {
            //                 store.commit(
            //                     AppMutation.SET_FULL_SCREEN,
            //                     screenfull.isFullscreen
            //                 )
            //             })
            //     }
            // }

            if (isAMobile()) {
                showMainView.value = false
                setTimeout(() => {
                    handleResizeWindow()
                    showMainView.value = true
                }, 500)
            }
        }

        const requestFullScreen = () => {
            if (screenfull.isEnabled) {
                const docEl = document.getElementById('app') as HTMLElement
                if (!screenfull.isFullscreen) {
                    screenfull
                        .request(docEl, { navigationUI: 'hide' })
                        .then(() => {
                            store.commit(
                                AppMutation.SET_FULL_SCREEN,
                                screenfull.isFullscreen
                            )
                        })
                } else {
                    screenfull.exit().then(() => {
                        store.commit(
                            AppMutation.SET_FULL_SCREEN,
                            screenfull.isFullscreen
                        )
                    })
                }
            }
        }

        const handleDialogEvent = (e: MessageBoxEvent) => {
            if (e.type === MessageBoxActionType.close) {
                if (dialogCollection.value.includes(e.name)) {
                    const _i = dialogCollection.value.indexOf(e.name)
                    dialogCollection.value.splice(_i, 1)
                }

                if (e.name === 'lang') showLanguageDialogBox.value = false
                else if (e.name === 'edit-custom-chip')
                    showEditChipMsg.value = false
                else if (e.name === 'setting') showSetting.value = false
                else if (e.name === 'edit-password')
                    showEditPassword.value = false
                else if (e.name === 'bet-history')
                    showRecordDialogBox.value = false
                else if (e.name === 'rule') showRuleDialogBox.value = false
                else if (e.name === 'media-setting')
                    showMediaSetting.value = false
            } else if (e.type === MessageBoxActionType.open) {
                if (!dialogCollection.value.includes(e.name))
                    dialogCollection.value.push(e.name)
                if (e.name === 'lang') showLanguageDialogBox.value = true
                else if (e.name === 'edit-custom-chip')
                    showEditChipMsg.value = true
                else if (e.name === 'setting') showSetting.value = true
                else if (e.name === 'edit-password')
                    showEditPassword.value = true
                else if (e.name === 'bet-history')
                    showRecordDialogBox.value = true
                else if (e.name === 'rule') showRuleDialogBox.value = true
                else if (e.name === 'media-setting')
                    showMediaSetting.value = true
            }
        }

        const closeAllDialogs = () => {
            const _arr = [...dialogCollection.value]
            _arr.forEach((_m) => {
                const _ev: MessageBoxEvent = {
                    type: MessageBoxActionType.close,
                    name: _m as DialogBoxName
                }

                handleDialogEvent(_ev)
            })
        }
        //#endregion

        //#region MJSocket Helpers

        //#endregion
        const showLoading = computed(
            (): boolean => store.getters['showLoading']
        )

        const canPlaySound = computed(
            (): boolean => store.getters['canPlaySound']
        )
        const isUserInteracted = computed(
            (): boolean => store.getters['isUserInteracted']
        )
        const playerTimeOut = computed(
            (): number => store.getters['playerTimeOut']
        )

        const hasVisibleDialog = computed(
            () => dialogCollection.value.length > 0
        )

        const memberid = computed(() => store.getters['memberid'])

        const token = computed(() => store.getters['token'])

        const musicVolume = computed((): number => store.getters['musicVolume'])
        const soundVoulume = computed(
            (): number => store.getters['soundVolume']
        )

        const musicCanPlay = computed(
            (): number => store.getters['musicCanPlay']
        )

        watch(
            () => route.name,
            (_n) => {
                if (typeof _n === 'string' && _n === ROUTES.LOGIN) {
                    closeAllDialogs()
                    store.commit(AppMutation.HIDE_LOADING)
                    audioPlayer.StopMusic()
                } else {
                    if (musicCanPlay.value === 1) audioPlayer.PlayMusic()
                }
            }
        )

        watch(
            () => deviceOrientation.value,
            () => {
                closeAllDialogs()
            }
        )

        return {
            showSetting,
            showMainView,
            isMobile,
            appRoot,
            showLoading,
            toastMessage,
            toastMessageCounter,
            hasVisibleDialog,
            showLanguageDialogBox,
            showSettingDialogBox,
            showEditPassword,
            showAgreeDialogBox,
            showChipsDialogBox,
            showRuleDialogBox,
            showRecordDialogBox,
            showMessageDialogBox,
            showMessageDialogTipBox,
            showMessageDialogTipBoxConcent,
            showMessageDialogVipBox,
            showMessageDialogMediaSettingBox,
            showGoodWaySetting,
            showAvatarDialog,
            showWitchPlayMsgBox,
            showCancelConfirmMessage,
            showuserInfoAndBetRecord,
            refVar,
            showMobileRecord,
            showEditChipMsg,
            showMediaSetting,
            requestFullScreen,
            initSound
        }
    }
})
